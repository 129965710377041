import React from 'react' 
import { SlideshowLightbox } from 'lightbox.js-react'
import { Link } from 'react-router-dom'
const ComputerLab = () => {
  return (
     <>
     <div className="innerslide">
     <h3>Computer Lab</h3>
     <ul className="braedcrumb">
         <li><Link to="/">Home</Link></li>
         <li>Infrastructure</li>
         <li>Computer Lab</li>
     </ul>
</div>
<div className="innersec">
 <div className="container">
     <div className="row">
          <div className="col-lg-4 col-md-6">
                 <div className="laboratoriesimg">
                 <SlideshowLightbox><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/computer-lab1.jpg' className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox>
                    </div>
          </div>
          <div className="col-lg-4 col-md-6">
                 <div className="laboratoriesimg">
                 <SlideshowLightbox><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/computer-lab2.jpg' className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox>
                    </div>
          </div>
          <div className="col-lg-4 col-md-6">
                 <div className="laboratoriesimg">
                 <SlideshowLightbox><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/computer-lab3.jpg' className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox>
                    </div>
          </div>
          <div className="col-lg-4 col-md-6">
                 <div className="laboratoriesimg">
                 <SlideshowLightbox><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/computer-lab4.jpg' className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox>
                    </div>
          </div>
          <div className="col-lg-4 col-md-6">
                 <div className="laboratoriesimg">
                 <SlideshowLightbox><img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/computer-lab5.jpg' className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox>
                    </div>
          </div>
     </div>
 </div>
</div>
     </>
  )
}

export default ComputerLab
