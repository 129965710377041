import React from 'react'

const SkilingInfoprops = (props) => {
  return (
    <>
       <div className="skillingcirclebox wow animate__animated animate__fadeInLeft">
          <div className="skillingcircleboximg">
                <img src={props.image} className="img-fluid" alt="Lance Fort International School "/>
            </div>
            <p>{props.title}</p>
        </div>
    </>
  )
}

export default SkilingInfoprops
