import { useEffect, useState } from "react";
import React from 'react'

import { useNavigate } from "react-router-dom" 

import { getGalleryInner } from "../Service/Api";
//import { Link } from 'react-router-dom'
const Gallery = () => {
  const navigate = useNavigate()

  const [data, setData] = useState([]);
const [filterData, setFilteredData] = useState([]);
const [selectedYear, setSelectedYear] = useState();
const [year, setYear] = useState([]);


useEffect(() => {
  const fetchData = async () => {
    const galleryData = await getGalleryInner();
    setData(galleryData);
    const uniqueYears = Array.from(
      new Set(galleryData.map((item) => new Date(item.date).getFullYear()))
    );
    uniqueYears.sort((a, b) => b - a);
    setYear(uniqueYears);
    console.log(galleryData);
    setFilteredData(galleryData); // Initialize filteredData with all data
  };
  fetchData();
}, []);

useEffect(() => {
  // Filter data based on selected year
  if (selectedYear && selectedYear !== "All") {
    const filteredData = data.filter((item) => {
      const itemYear = new Date(item.date).getFullYear();
      return itemYear === parseInt(selectedYear);
    });
    setFilteredData(filteredData);
  } else {
    // If selected year is "All" or not selected, set filterData to all data
    setFilteredData(data);
  }
}, [selectedYear, data]); // Added 'data' as a dependency
; // Added 'data' as a dependency

const monthsOfYear = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const handleMonth = (month) => {
  return monthsOfYear[month - 1];
};

const handleModal = (id) => {
  navigate(`/SubGallery?id=${id}`);
};

    return (
        <>
            <div className="innerslide">
                <h3> Gallery</h3>
                <ul className="braedcrumb">
                    <li> Gallery</li>
                    <li> Photo Gallery</li>
                </ul>
            </div>
            <div className="innersec">
                <div className="container">
                    <div className="row tabs-dynamic">
                        <div className="col-md-3">
                            <div className="count-val">
                                <p>Total Count:  {filterData.length}</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="month-selection">
                            <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="yearSelection"
                >
                  <option value={"All"}>All</option>
                  {year.map((yr) => (
                    <option value={yr}>{yr}</option>
                  ))}
                  {/* <option value={2022}>2022</option>
                  <option value={2021}>2021</option>
                  <option value={2020}>2020</option>
                  <option value={2019}>2019</option>
                  <option value={2018}>2018</option> */}
                  {/* Add more years if necessary */}
                </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="month-selection">
                                <select className="monthSelect">
                            <option value="0">All Month</option>

                        </select>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="searchBox">
                            <input type="search" id="myInput" name="name" placeholder="Search Here.."/>
                            </div>
                        </div>
                    </div>
                    <div className="row years year2024 showYear">
                        {filterData.length > 0 &&
                            filterData.map((item, index) => ( 
<div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 galleryCount">
                       <div className="gallery-inner" onClick={() => handleModal(item?._id)}>
                       <div className="gallery-blk">
                           <div className="backDiv"></div>
                           <img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item?.attachments[0]}`}  className="img-fluid" alt="Lance Fort International School" />
                       </div>
                       <div className="gallery-content">
                       <div className="gallery-date">
                               <h6> {new Date(item.date).getDate()} <span>   {handleMonth(new Date(item.date).getMonth() + 1)}</span> </h6>
                                <div className="gall-num">
                               <h5><span>Number Of Photos :</span>{item?.attachments.length}</h5>
                           </div>
                           </div>
                           <div className="gallery-title">
                               <h3>{item.title}</h3>
                           </div>
                          
                           
                          <a onClick={()=>navigate('/sub-gallery')} className="view-more">View More <i className="bi bi-arrow-right"></i></a>
                       </div>
                   </div>
                   </div>
                            ))}
               
                  
               </div> 
                </div>
            </div>
        </>
    )
}

export default Gallery
