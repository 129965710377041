import React from 'react'
import { Link } from 'react-router-dom'
const AdmissionProcess = () => {
  return (
     <>
     <div className="innerslide">
     <h3>Admission Process</h3>
     <ul className="braedcrumb">
         <li><Link to="/">Home</Link></li>
         <li>Admission</li>
         <li>Admission Process</li>
     </ul>
</div>
<div className="innersec">
 <div className="container">
     <div className="row">
          <div className="col-lg-12">
            <h4 className="grdxt">Your child’s journey starts here</h4>
              <p><b>LFIS</b> welcomes students to an enriching and joyful learning experience. The Admission Procedure is completely transparent, and has been designed to ensure a suitable fit between what the parents and children expect from the School, and what the School in turn can offer to them.</p>
              <h5 className="mid-head">Age Eligibility (at the time of entrance)</h5>
              <p>Admission will be granted to candidates only if they fulfill the age criteria mentioned below:</p>
              <div className="table-responsive admisiontable">
                  <table className="table">
                     <thead>
                        <tr>
                            <th>GRADE </th>
                            <th>NUR </th>
                            <th>KG </th>
                            <th>I </th>
                            <th>II </th>
                            <th>III </th>
                            <th>IV </th>
                            <th>V </th>
                            <th>VI </th>
                            <th>VII </th>
                            <th>VIII </th>
                            </tr>
                                                </thead>
                                                <tbody>
                                                        <tr><td>AGE </td>
                                                        <td>3+ </td>
                                                        <td>4+ </td>
                                                        <td>5+ </td>
                                                        <td>6+ </td>
                                                        <td>7+ </td>
                                                        <td>8+ </td>
                                                        <td>9+ </td>
                                                        <td>10+ </td>
                                                        <td>11+ </td>
                                                        <td>12+ </td>
                                                        </tr>
                                                </tbody>
                                            </table>
                                        </div>
                        <h5 className="mid-head">Admissions Process</h5>
                        <p><b>Pre-Primary:</b> Parents applying for <b>Nursey & KG</b> will be required to fill out an Application Form. There will be no formal tests or interviews conducted on the child. Instead, the child will be observed in a group setting to assess his/her ability to interact in a school environment.</p>
                        <p>Grades 1 onwards: A simple, yet comprehensive three-stage admissions process will be used:</p>
                            <ul className="admisionli">
            <li> Initial Screening on the basis of an Application Form  </li>
            <li> Aptitude Test to determine child’s proficiency across different subject areas </li>
            <li> Interaction with child and parents to assess the child’s ability and eagerness to gain from the <b>LFIS </b>experience </li> 
            <li> Successful applicants will be required to carry out certain other formalities to complete the admission process </li>
            </ul>
                        <h5 className="mid-head">Parents/Guardians can obtain the Application Form in the following ways:</h5>
                        <div className="info">
                            <p><b>Collect from the School Campus</b> – Lance Fort International School, Opposite Sunil Colony, Burari, Delhi-110084 on payment of fees of <b>Rs. 500/</b>- in cash. Please retain receipt for future reference</p>
            <p><b>Download from the School Website</b> – <Link to="https://www.lancefortinternationalschool.com/">www.lancefortinternationalschool.com</Link> (The fee of <b>Rs. 400/</b>- will need to be paid along with the Registration Fee at the School campus at the time of submission of the Application Form)</p>

               </div>
          </div>
     </div>
 </div>
</div>
     </>
  )
}

export default AdmissionProcess
