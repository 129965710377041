import React from 'react'
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom' 
const Header = () => {
  return (
      <>
            <header>
            <div class="header wow animate__animated animate__slideInDown">
                <div class="header-left">
                    <Link to="/"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/logo.png"/></Link>
                </div>
                <div class="header-right">
                <div class="header-right-top">
                        <ul>
                            <li><NavLink to="#"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/fbicn.png" class="img-fluid"/></NavLink></li>
                            <li><NavLink to="#"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/insticn.png" class="img-fluid"/></NavLink></li>
                            <li><NavLink to="#"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/youticn.png" class="img-fluid"/></NavLink></li>
                            <li><NavLink to="#"><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/twittericn.png" class="img-fluid"/></NavLink></li>
                        </ul>
                    
                        <Link to="tel:7303799381" class="mainbtnw">7303799381 <span><i class="bi bi-telephone-fill"></i></span></Link>
                </div>
                <div class="header-right-bottom">
                    <nav class="navbar navbar-expand-lg">
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"><i class="bi bi-list"></i></span>
            </button>
            <div class="collapse navbar-collapse" id="main_nav">
            <ul class="navbar-nav">
                <li class="nav-item"><Link class="nav-link" to="/" id="home">Home</Link></li>
                <li class="nav-item dropdown">
            <div class="nav-link  dropdown-toggle" to="#" data-bs-toggle="dropdown" id="overview"> overview </div>
            <ul class="dropdown-menu">
            <li><NavLink to="#" class="dropdown-item" id="overview1">About Us</NavLink></li>
            <li><NavLink to="ChairmanWelcome" class="dropdown-item" id="overview2">Chairman's Welcome</NavLink></li>
            <li><NavLink to="DirectorWelcome" class="dropdown-item" id="overview3">Director's Welcome</NavLink></li>
            <li><NavLink to="PrincipalWelcome" class="dropdown-item" id="overview4">Principal's Welcome</NavLink></li>
            </ul>
            </li>
            <li class="nav-item dropdown">
            <div class="nav-link  dropdown-toggle" to="#" data-bs-toggle="dropdown" id="admission"> Admission </div>
            <ul class="dropdown-menu">
            <li><NavLink to="GettingStartedatLfis" class="dropdown-item" id="admission1">Getting Started at LFIS</NavLink></li>
            <li><NavLink to="AdmissionProcess" class="dropdown-item" id="admission2">Admission Process</NavLink></li>
            </ul>
            </li>
                <li class="nav-item dropdown">
            <div class="nav-link  dropdown-toggle" to="#" data-bs-toggle="dropdown" id="learning"> Learning </div>
            <ul class="dropdown-menu">
                <li><NavLink to="EarlyLearningAges" class="dropdown-item" id="learning1">Early Learning | Ages 3-5</NavLink></li>
                <li><NavLink to="PrimaryAges" class="dropdown-item" id="learning2">Primary | Ages 6-11</NavLink></li>
                <li><NavLink to="MiddleAges" class="dropdown-item" id="learning3">Middle | Ages 11-14</NavLink></li>
            </ul>
            </li>
            
            
                <li class="nav-item dropdown">
            <div class="nav-link  dropdown-toggle" to="#" data-bs-toggle="dropdown" id="infrastructure"> Infrastructure </div>
            <ul class="dropdown-menu">
            <li><NavLink to="Sports" class="dropdown-item" id="infrastructure1">Sports</NavLink></li>
                <li><NavLink to="#" class="dropdown-item" id="infrastructure2">Classrooms</NavLink></li>
                <li><NavLink to="#" class="dropdown-item" id="infrastructure3">Library</NavLink></li>
                <li><NavLink to="ComputerLab" class="dropdown-item" id="infrastructure4">Computer Lab</NavLink></li>
                <li><NavLink to="#" class="dropdown-item" id="infrastructure5">Safety & Security</NavLink></li>
            </ul>
            </li>
            <li class="nav-item"><Link class="nav-link" to="facilities" id="facilities">Facilities </Link></li>
            
                    <li class="nav-item dropdown">
            <div class="nav-link  dropdown-toggle" to="#" data-bs-toggle="dropdown" id="gallery"> Gallery 
            </div>
            <ul class="dropdown-menu">
                <li><NavLink to="Gallery" class="dropdown-item" id="gallery1">Photo Gallery</NavLink></li>
                <li><NavLink to="VideoGallery" class="dropdown-item" id="gallery2">Video Gallery</NavLink></li>
            </ul>
            </li>
            <li class="nav-item"><Link class="nav-link" to="ContactUs" id="contact">Contact Us </Link></li>
            </ul>
            </div> 
            </nav>
            <Link to="ParentGuidelines" class="mainbtn">Parent Portal <span><img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/parenticn.png"/></span></Link>
                </div>
                </div>
            </div> 
            </header>
      </>
  )
}

export default Header
