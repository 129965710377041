import React from 'react'

const MidSecInfoprop = (props) => {
  return (
    <>
    <div className="midsecbox">
      <div className="midsecboximg">
        <img src={props.image} alt="Lance Fort International School" /> 
        </div>
       <p>{props.title}</p>
    </div>
    </>
  )
}

export default MidSecInfoprop
