import React, { useEffect, useState } from "react";

import { getHighlight } from "../Service/Api";
import { Link } from 'react-router-dom';
const Highlights = () => {
   const [data, setData] = useState([]);

   useEffect(() => {
     const getData = async () => {
       const datas = await getHighlight();
       setData(datas);
     };
     getData();
   }, []);
 
   useEffect(() => {
     console.log("events_data", data);
   }, [data]);
 
   


  return (
     <>
     <div className="highlights">
        <marquee onmouseover="stop();" onmouseout="start();" direction="left" behavior="scroll">
        <p>{data.map((item, index) => (
            <Link to={`https://d280nq1n4mqyso.cloudfront.net/api/image/${item.attachments}`} target="_blank">{item.title}<i className="bi bi-paperclip"></i> | </Link> 
            ))}</p>
        </marquee>
     </div>
     </>
  )
}

export default Highlights
