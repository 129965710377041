import React from 'react' 
import OwlCarousel from 'react-owl-carousel';
const SkillingSlider = () => {
    var settings = {
        loop: true,
        margin:10,
        responsiveClass: true,
        autoplay:true,
       dots:true,
        items:4,
        responsive: {
          0: {
            items: 1,
          },
          768: {
            items: 2,
          },
          992: {
            items:3,
          },
           1600: {
            items:4,
          }
        }
  };
  return (
    <>
    <div className="row">
        <div className="col-lg-12">
            <OwlCarousel className='owl-theme infrastructureslide wow animate__animated animate__slideInUp' loop margin={10}  {...settings}>
                <div className="item">
                <div className="infrastructure">
                    <div className="infrastructureimg">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/infrastructure1.jpg" className="img-fluid" alt=" Lance Fort International School"/>
                    </div>
                    <div className="infrastructuretitle">
                        <p>Art & Craft</p>
                    </div>
                    <div className="infrastructuredesc">
                        <p>We prepare our kids to give life to their creative ideas. Our art & craft sessions help them hone their artistic skills and inspire them to create their own masterpieces.</p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="infrastructure">
                    <div className="infrastructureimg">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/infrastructure2.jpg" className="img-fluid" alt=" Lance Fort International School"/>
                    </div>
                    <div className="infrastructuretitle">
                        <p> Music & Dance</p>
                    </div>
                    <div className="infrastructuredesc">
                        <p> Music transcends time, space, and culture. At Lance Fort, music is an intrinsic part of holistic education. It fosters brain functioning and contributes to socio-emotional development.</p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="infrastructure">
                    <div className="infrastructureimg">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/infrastructure3.jpg" className="img-fluid" alt=" Lance Fort International School"/>
                    </div>
                    <div className="infrastructuretitle">
                        <p> Outdoor Sports</p>
                    </div>
                    <div className="infrastructuredesc">
                        <p> Sports and physical activities are an eminent part of our curriculum at all levels. This helps students promote overall well-being, and develop social skills and discipline. </p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="infrastructure">
                    <div className="infrastructureimg">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/infrastructure4.jpg" className="img-fluid" alt=" Lance Fort International School"/>
                    </div>
                    <div className="infrastructuretitle">
                        <p> Theatre Education</p>
                    </div>
                    <div className="infrastructuredesc">
                        <p>Students learn the fundamentals of acting and stagecraft with our engaging drama classes. Theatre education empowers them to be better communicators and inspire self-confidence. </p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="infrastructure">
                    <div className="infrastructureimg">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/infrastructure1.jpg" className="img-fluid" alt=" Lance Fort International School"/>
                    </div>
                    <div className="infrastructuretitle">
                        <p>Art & Craft</p>
                    </div>
                    <div className="infrastructuredesc">
                        <p>We prepare our kids to give life to their creative ideas. Our art & craft sessions help them hone their artistic skills and inspire them to create their own masterpieces.</p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="infrastructure">
                    <div className="infrastructureimg">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/infrastructure2.jpg" className="img-fluid" alt=" Lance Fort International School"/>
                    </div>
                    <div className="infrastructuretitle">
                        <p> Music & Dance</p>
                    </div>
                    <div className="infrastructuredesc">
                        <p> Music transcends time, space, and culture. At Lance Fort, music is an intrinsic part of holistic education. It fosters brain functioning and contributes to socio-emotional development.</p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="infrastructure">
                    <div className="infrastructureimg">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/infrastructure3.jpg" className="img-fluid" alt=" Lance Fort International School"/>
                    </div>
                    <div className="infrastructuretitle">
                        <p> Outdoor Sports</p>
                    </div>
                    <div className="infrastructuredesc">
                        <p> Sports and physical activities are an eminent part of our curriculum at all levels. This helps students promote overall well-being, and develop social skills and discipline. </p>
                    </div>
                </div>
            </div>
            <div className="item">
                <div className="infrastructure">
                    <div className="infrastructureimg">
                        <img src="https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/infrastructure4.jpg" className="img-fluid" alt=" Lance Fort International School"/>
                    </div>
                    <div className="infrastructuretitle">
                        <p> Theatre Education</p>
                    </div>
                    <div className="infrastructuredesc">
                        <p>Students learn the fundamentals of acting and stagecraft with our engaging drama classes. Theatre education empowers them to be better communicators and inspire self-confidence. </p>
                    </div>
                </div>
            </div>
            </OwlCarousel>
            </div>
       </div>
    </>
  )
}

export default SkillingSlider
