import React from 'react' 
import { Link } from 'react-router-dom'
const GettingStartedatLFIS = () => {
  return (
    <>
            <div className="innerslide">
            <h3> Getting Started at LFIS </h3>
            <ul className="braedcrumb">
                <li><Link to="/">Home</Link></li>
                <li>Admission</li>
                <li> Getting Started at LFIS </li>
            </ul>
        </div>
        <div className="innersec">
        <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <div className="admisionimg">
                        <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/admission.jpg' className="img-fluid"/>
                    </div>
              <p>Whether you are astudentinterested to learn more about our school, aparentchoosing the right school for your children, or someone interested infuture career opportunities, we hope that our website gives a small glimpse of life and learning at this remarkable school. Choosing the right schoolis one of the most important decisions you will make. At the <b>Lance Fort International School</b>, we want to support you in this process by learning about what matters to you.</p>

                <div className="quotes">
                <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/quotesleft.png' className="img-fluid"/>
                <p>Our school has a rich tradition of academic and personal excellence.Our missiondrives our school community:<b>“A Gateway for Knowledge & Wisdom” </b>.</p>
                <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/quotesright.png' className="img-fluid leftquo"/>
                </div>
                <p>Today, <b>LFIS</b> students are intellectually curious and socially aware. They are actively involved, dedicated to personal growth and excellence, driven by compassion, and committed to serving others. They are artists, athletes, and innovators. Our Admissions Team seeks prospective students who will embrace all that it means to be a <b>LFIS</b> student.</p>
                <p>We hope you visit us,take a tour,meet with an Admissions officer,talk to students, and learn more about <b>LFIS</b>. We look forward to seeing you in our  <b>Temple of Wisdom!</b></p>
                        </div>
                    
                    </div>
                </div>
                </div>
    </>
  )
}

export default GettingStartedatLFIS
