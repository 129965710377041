import React, { useEffect, useState } from "react";
// import gallery1 from'https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/gallery1.jpg'
// import gallery2 from'https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/gallery2.jpg'
// import gallery3 from'https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/gallery3.jpg'
// import gallery4 from'https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/gallery4.jpg'
// import gallery5 from'https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/gallery5.jpg'
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate hook
import { getSubGallery } from "../Service/Api";
import {SlideshowLightbox} from 'lightbox.js-react'
import { Link } from 'react-router-dom'

const SubGallery = () => {
    
    const navigate = useNavigate();
  const location = useLocation();
  const id = new URLSearchParams(location.search).get("id");
  const [data, setData] = useState("");
  const [index, setIndex] = React.useState(-1);

  console.log(id);

  useEffect(() => {
    const getData = async () => {
      try {
        let subgalleryData = await getSubGallery(id);
        console.log(subgalleryData);
        setData(subgalleryData);
      } catch (error) {
        console.log(error);
      }
    };
    getData();
  }, []);

  console.log(data);

  const slides = data?.attachments?.map(img => ({
    src: `https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`
  }));

  return (
     <>
     <div className="innerslide">
     <h3>Sub Gallery</h3>
     <ul className="braedcrumb">
     <li> Gallery</li>
     <li> Photo Gallery</li>
     <li>{data.title}</li>
     </ul>
 </div>
     <div className="innersec">
        <div className="container">
               <div className="row tabs-dynamic">
                 <div className="col-md-6 col-xl-3">
                    <div className="count-val">
                        <p><Link to="/Gallery"> <i className="bi bi-arrow-left"></i> Go Back</Link>  </p>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="month-selection">
                        <p><span>Event Date:</span> {" "}
                  {new Date(data.date).toLocaleDateString("en-GB")}</p>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="month-selection">
                        <p><span>Last Update:</span> {" "}
                  {new Date(data.date).toLocaleDateString("en-GB")}</p>
                    </div>
                </div>
                <div className="col-md-6 col-xl-3">
                    <div className="searchBox">
                        <p><span>No. Of Photos:</span> {data?.attachments?.length}</p>
                    </div>
                </div>
            </div>
              <div className="row">
                <div className="col-md-12">
                    <h4 className="gallery-title">{data.title}</h4>
                    <p>{data.description} </p>
                </div>
                {data !== "" &&
              data?.attachments.map((img,index) => (
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4 col-xxl-3 galleryCount sub-gallerycount">
                    <div className="gallery-blk">
                        <div className="backDiv"></div>
                        <SlideshowLightbox><img src={`https://d280nq1n4mqyso.cloudfront.net/api/image/${img}`} onClick={() => setIndex(index)} className="img-fluid border-img w-full rounded" alt="Lance Fort International School" /></SlideshowLightbox>
                        
                    </div>
                </div>
                 ))} 
      
               
            </div> 
     </div>
</div>
     </>
  )
}

export default SubGallery
