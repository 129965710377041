import React from 'react'
import { Link } from 'react-router-dom' 
const ParentGuidelines = () => {
  return (
    <>
    <div className="innerslide">
    <h3>Parent Guidelines</h3>
    <ul className="braedcrumb">
        <li><Link to="/">Home</Link></li>
        <li>Parent Guidelines</li>
    </ul>
</div>
<div className="innersec">
<div className="container">
     <div className="row">
                     <div className="erp_sec">
                           <h4 className="guideheading">How to LOG IN Parent Portal?</h4>
                            <p className="details"> 1. Open Google Chrome <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/chrome.png'/> / Mozilla Firefox <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/mzilla.png'/> or Internet Explorer <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/internet.png'/>. </p>
                            <p className="details"> 2. Write the school URL  <Link to="https://lancefort.campuscare.cloud/" className="parent-link" target="_blank"><strong> lancefort.campuscare.cloud</strong></Link> in address bar. (you will be routed to the Log In credential page).</p>
                            <p className="details"> 3. Then, select the Parent icon and enter the USER ID, PASSWORD and CAPTCHA CODE given below. Then press SIGN IN button.</p>
                            <p className="details"> Once logged in, you are welcomed with various information relating to assignments, news, Circulars, date sheet, fee details etc that are self explanatory.  Wish you to go through every links that are provided and explore more. </p>

                           <div className="clr10"></div>

                           <div>
                            <h4 className="guideheading">How to RESET PASSWORD in Parent Portal?</h4>
                            <p className="details"> 1. Open Google Chrome <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/chrome.png'/>/ Mozilla Firefox <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/mzilla.png'/> or Internet Explorer <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/internet.png'/>. </p>
                            <p className="details"> 2. Write the school URL  <Link to="https://lancefort.campuscare.cloud/" className="parent-link" target="_blank"><strong> lancefort.campuscare.cloud</strong></Link> in address bar. (you will be routed to the Log In credential page).</p>
                            <p className="details"> 3. After click on PARENTS menu, click on “Forgot password?” text given below the Sign In button.</p>
                            <p className="details"> 4. Enter Registered Mobile No. and click on  “Reset” Button.</p>
                            <p className="details"> 5. You will get an SMS on your registered mobile no. along with login credentials.</p>
                           </div>
                           <div className="clr25"></div>


                              <div>

                            <div className="clr10"></div>
                            <h4 className="guideheading">How to make Online Payment ?</h4>
                            <p className="details"> 1. Open Google Chrome <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/chrome.png'/> / Mozilla Firefox <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/mzilla.png'/> or Internet Explorer <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/internet.png'/>. </p>
                            <p className="details"> 2. Enter the school URL  <Link to="https://lancefort.campuscare.cloud/" className="parent-link" target="_blank"><strong> lancefort.campuscare.cloud</strong> </Link> in address bar and you will be routed to the Log In page. </p>
                            <p className="details">3. Now, enter the USER ID and then enter your PASSWORD and Click on ‘Sign in’ button.</p>
                            <p className="details"> 4. After login, click on the Online Payment menu. </p>
                           <p className="details"> 5. Select the installment to pay & click on the proceed button.</p>
                           <p className="details"> 6. Now, you will be redirected to Payment Gateway page.</p>
                           <p className="details"> 7. Choose the Pay mode : Credit Card/Debit Card/Net banking & fill the details to complete the payment process.</p>
                           <p className="details"> 8. After successful payment, you will receive the fee receipt & SMS on successful fee payment.</p>
                           <p className="details"><em><strong style={{color: "red"}}>Note :</strong> If for any reason, the fee payment output details are not displayed on your screen after you have made payments, please check the details in “My Payment” in Finance link. If your accounts get debited/deducted and receipt is not available, you are advised to contact school office or mail us.</em></p>
                           </div>
                           <div className="clr10"></div>
                          

                              <div className="animated fadeInDown">
                                      <h4 className="guideheading">How to Install and Operate MOBILE APP? </h4>
                                <p className="details"> 1. Go to <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/playstoreicn.png'/> Store / <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/appleicn.png'/> and Search "CampusCare®". </p>
                                <p className="details"> 2. Install and open the app. </p>
                                <p className="details"> 3. Enter school web portal URL <strong><a target="_blank" href="https://lancefort.campuscare.cloud/" className="parent-link"> lancefort.campuscare.cloud</a></strong> in link bar verify. </p>
                                <p className="details"> 4. After verifying, you will get an option for User ID & Password.</p>
                                <p className="details"> Once logged in, you are welcomed with various information relating to assignments, news, circulars, date sheet, fee details etc that are self  Explanatory. Wish you to go through every links that are provided and explore more </p>
                                <p className="details"><em><strong style={{color: "red"}}>Note :</strong> Kindly update your mobile apps time to time to avoid difficulties during usage.</em> </p>
                              </div>
                              <div className="clr25"></div>
                         
                           <div className="assistance">
                            <h4 className="guideheading">Please call or write for assistance:</h4>
                            <p className="details">PARENT HELP DESK (8:00 AM to 8:00 PM)  , E-Mail:  <Link to="mailto:parentdesk@entab.in" className="parent-link"> parentdesk@entab.in</Link></p>
                           </div>
                           <div className="clr25"></div>
                        </div>    
               </div>
</div>
</div>
    </>
  )
}

export default ParentGuidelines
