import React from 'react' 
import { Link } from 'react-router-dom'
const Facilities = () => {
  return (
    <>
    <div class="innerslide">
        <h3>Facilities</h3>
        <ul class="braedcrumb">
        <li><Link to="/">Home</Link></li>
            <li>Facilities</li>
        </ul>
    </div>
    <div className="innersec">
    <div className="container">
        <div className="row">
             <div className="col-lg-12">
                 <p>The sprawling World className infrastrucure splendid over 2 acre strikes a balance between modern facilities and natural surroundings, creating an atmosphere that is ideal for students to learn, explore, and grow in perfect harmony with nature. The academic, co-curricular and ancillary zones are purpose-built with state-of-the-art infrastructure and a broad range of provisions. </p>
             </div>
             <div className="facilities">
                   <div className="facilitiesbox facilitiesboxleft">
                           <div className="facilitiesboximg">
                               <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/Academic.jpg' className="img-fluid"/>
                           </div>
                           <h4 className="mid-head">Academic Zone</h4>
                           <ul className="learningli">
                                  <li> Spacious (500 sq. ft. each) and innovatively designed classNamerooms, with internet connectivity, Modern Interactive display.   </li>
                                   <li> Suite of separate laboratories to encourage experiential learning </li>
                                   <li> Well-stocked Library to facilitate independent research and learning </li>
                                   <li> Learning Lounges for students to hold group discussions or carry out self-study </li>
                           </ul>
                            <div className="clr"></div>
                   </div>
                   <div className="facilitiesbox facilitiesboxright">
                           <div className="facilitiesboximgright">
                               <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/Co-Curricular.JPEG' className="img-fluid"/>
                           </div>
                           <h4 className="mid-head">Co-Curricular Zone</h4>
                           <ul className="learningli">
                                  <li> Playing and practice grounds for cricket, volleyball &  football  </li>
                                  <li> Courts for badminton, basketball, Lawn tennis and volleyball  </li>
                                  <li> Running track for athletics training  </li>
                                  <li> Games Halls for table tennis, billiards, chess, martial arts and other indoor games  </li>
                                  <li> Suite of rooms for music and dance, equipped with superior sound and light systems  </li>
                                  <li> Art Studios for drawing, painting and other artistic interests </li>
                           </ul>
                            <div className="clr"></div>
                   </div>
                   <div className="facilitiesbox facilitiesboxleft">
                           <div className="facilitiesboximg">
                               <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/Garden.jpeg' className="img-fluid"/>
                           </div>
                           <h4 className="mid-head">Garden of wonders</h4>
                           <p>Our innovative programmes lay the foundation to ensure healthy bodies and minds thrive. We nurture your children’s respect for the environment and appreciation of their role in caring for our planet.</p>
                          <p>Thus to make above mentioned objectives possible we’ve sophistically developed <b> “Garden of Wonders” </b> splendid over 1 Acre. Spacious garden sorrounding sports ground are attractively landscaped with carefully selected trees, bushes and flowering plants, creating a welcoming environment & providing pleasant meeting places for pupils during breaks & sports activities by keeping the notion in mind <b>“Our body is a bilogical machine & its fuel is nature”.</b></p>
                  
                   <div className="clr"></div> </div>
                    <div className="facilitiesbox facilitiesboxright">
                           <div className="facilitiesboximgright">
                               <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/Smart-classes-img.jpg' className="img-fluid"/>
                           </div>
                           <h4 className="mid-head">Smart classNamees/Multimedia Facilities</h4>
                            <p> Teaching through the latest pedagogy, is the on-going motto of the school. All the teachers are trained to teach through smart className, to make teaching enjoyable and refreshing. 
                            </p>
                            <div className="clr"></div>
                   </div>
                   <div className="facilitiesbox facilitiesboxleft">
                           <div className="facilitiesboximg">
                               <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/Safety-security-img.jpg' className="img-fluid"/>
                           </div>
                           <h4 className="mid-head">Safety & security </h4>
                           <p>Lance Fort Int. School gives maximum importance to the safety of its students. 24x7 advanced monitoring and tracking systems has therefore been installed in the entire campus. The entire campus is under survelliance using IP based and other tools. Security guards are also selected only after through evaluation and referral check. Visitors are not allowed into the campus except with prior appointment.</p>
                          <div className="clr"></div>
                           </div>
                        <div className="facilitiesbox facilitiesboxright">
                           <div className="facilitiesboximgright">
                               <img src='https://d280nq1n4mqyso.cloudfront.net/api/image/LFIS/public/Images/Health-Hygiene-img.jpg' className="img-fluid"/>
                           </div>
                           <h4 className="mid-head">Health & Hygiene</h4>
                            <p>At LFIS, health & hygiene of the students is given prime importance. This is ensured by implementing the following measures:</p>
                             <ul className="learningli">
                                <li> A non-compromising attitude towards cleanliness.</li>
                                <li> Staff training all levels</li>
                                <li> Close supervision and monitoring of areas prone to be unhygienic</li>
                                <li> Educaing and creating awareness among students. </li>
                                </ul>
                                    <div className="clr"></div>
                   </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Facilities
